.gallery {
  --g: 8px;
  --s: 400px;

  display: grid;
  border-radius: 50%;
}

.gallery > img {
  position: relative;
  grid-area: 1/1;
  width: 400px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
  transform: translate(var(--_x, 0), var(--_y, 0));
  cursor: pointer;
  z-index: 0;
  transition: 0.6s, z-index 0s 0.6s;
}

.gallery img:hover {
  --_i: 1;
  z-index: 1;
  transition: transform 0.2s, clip-path 0.6s 0.2s, z-index 0s;
}

.gallery > img.open {
  --_i: 1;
  z-index: 10;
  transition: transform 0.2s, clip-path 0.6s 0.2s, z-index 0s;
  transform: translate(0, 0);
}

.gallery > img.closed {
  transform: translate(0, 0);
}

.gallery:hover img {
  transform: translate(0, 0);
}

.gallery > img:nth-child(1) {
  clip-path: polygon(
    50% 50%,
    calc(50% * var(--_i, 0)) calc(120% * var(--_i, 0)),
    0 calc(100% * var(--_i, 0)),
    0 0,
    100% 0,
    100% calc(100% * var(--_i, 0)),
    calc(100% - 50% * var(--_i, 0)) calc(120% * var(--_i, 0))
  );
  --_y: calc(-1 * var(--g));
}

.gallery > img:nth-child(2) {
  clip-path: polygon(
    50% 50%,
    calc(100% - 120% * var(--_i, 0)) calc(50% * var(--_i, 0)),
    calc(100% - 100% * var(--_i, 0)) 0,
    100% 0,
    100% 100%,
    calc(100% - 100% * var(--_i, 0)) 100%,
    calc(100% - 120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0))
  );
  --_x: var(--g);
}
.gallery > img:nth-child(3) {
  clip-path: polygon(
    50% 50%,
    calc(100% - 50% * var(--_i, 0)) calc(100% - 120% * var(--_i, 0)),
    100% calc(100% - 120% * var(--_i, 0)),
    100% 100%,
    0 100%,
    0 calc(100% - 100% * var(--_i, 0)),
    calc(50% * var(--_i, 0)) calc(100% - 120% * var(--_i, 0))
  );
  --_y: var(--g);
}
.gallery > img:nth-child(4) {
  clip-path: polygon(
    50% 50%,
    calc(120% * var(--_i, 0)) calc(50% * var(--_i, 0)),
    calc(100% * var(--_i, 0)) 0,
    0 0,
    0 100%,
    calc(100% * var(--_i, 0)) 100%,
    calc(120% * var(--_i, 0)) calc(100% - 50% * var(--_i, 0))
  );
  --_x: calc(-1 * var(--g));
}

.galleryContainer {
  display: grid;
  place-content: center;
  background: transparent;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.15em; /* Adjust as needed */
  animation: typing 1.5s steps(40, end), blink-caret 0.5s step-end infinite;
}

@media (min-width: 640px) {
  .typewriter {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.15em; /* Adjust as needed */
    animation: typing 2.5s steps(40, end), blink-caret 0.5s step-end infinite;
  }
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.typeFirst {
  animation-delay: 0.3s;
}

.typeSecond {
  animation-delay: 1.5s;
}

.typeThird {
  animation-delay: 2.4s;
}
