.background {
  background-color: #f3f4f6;
  background-image: url("../../img/asfalt-dark.webp");
  transform-style: preserve-3d;
  transform: perspective(100rem);
  cursor: pointer;
}

.tiltBoxWrap {
  width: 800px;
  height: 700px;
  position: relative;
  transition: all 0.6s ease-out;
  perspective: 1000px;
  box-shadow: black;
}

.tiltBox {
  width: 100%;
  height: 100%;
  /* box-shadow: 0 0 16px 2px rgb(13, 157, 68); */
  position: relative;
  transition: all 0.6s ease-out;
  transform: rotateX(0deg) rotateY(0deg);
  perspective: 1000px;
  transform-style: preserve-3d;
}

.content {
  text-shadow: 2px 2px 3px rgba(52, 154, 69, 0.71);
}

.tiltBoxBorder::before,
.tiltBoxBorder::after {
  content: "";
  width: 0;
  height: 0;
  border: 4px double green;
  border-radius: 40px;

  position: absolute;
  z-index: 2;
  opacity: 0.3;

  transition: 0.6s;
  transition-delay: 0.1s;
}

.tiltBoxBorder::before {
  top: 1.5rem;
  right: 1.5rem;
  border-bottom-width: 0;
  border-left-width: 0;
}

.tiltBoxBorder::after {
  bottom: 1.5rem;
  left: 1.5rem;
  border-top-width: 0;
  border-right-width: 0;
}

.tiltBoxBorderHovered::before,
.tiltBoxBorderHovered::after {
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
}

.tOver:hover ~ .tiltBoxBorder::before,
.tOver:hover ~ .tiltBoxBorder::after {
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
}

.tOverReverse:hover ~ .tiltBoxBorder::before,
.tOverReverse:hover ~ .tiltBoxBorder::after {
  width: calc(100% - 3rem);
  height: calc(100% - 3rem);
}

.tOver {
  width: 33.333%;
  height: 33.333%;
  position: absolute;
  z-index: 1;
}

.tOverReverse {
  width: 33.333%;
  height: 33.333%;
  position: absolute;
  z-index: 1;
}

.tOver:nth-child(1) {
  left: 0;
  top: 0;
}
.tOver:nth-child(2) {
  left: 33.333%;
  top: 0;
}
.tOver:nth-child(3) {
  left: 66.666%;
  top: 0;
}
.tOver:nth-child(4) {
  left: 0;
  top: 33.333%;
}
.tOver:nth-child(5) {
  left: 33.333%;
  top: 33.333%;
}
.tOver:nth-child(6) {
  left: 66.666%;
  top: 33.333%;
}
.tOver:nth-child(7) {
  left: 0;
  top: 66.666%;
}
.tOver:nth-child(8) {
  left: 33.333%;
  top: 66.666%;
}
.tOver:nth-child(9) {
  left: 66.666%;
  top: 66.666%;
}

.tOverReverse:nth-child(1) {
  left: 0;
  top: 0;
}
.tOverReverse:nth-child(2) {
  left: 33.333%;
  top: 0;
}
.tOverReverse:nth-child(3) {
  left: 66.666%;
  top: 0;
}
.tOverReverse:nth-child(4) {
  left: 0;
  top: 33.333%;
}
.tOverReverse:nth-child(5) {
  left: 33.333%;
  top: 33.333%;
}
.tOverReverse:nth-child(6) {
  left: 66.666%;
  top: 33.333%;
}
.tOverReverse:nth-child(7) {
  left: 0;
  top: 66.666%;
}
.tOverReverse:nth-child(8) {
  left: 33.333%;
  top: 66.666%;
}
.tOverReverse:nth-child(9) {
  left: 66.666%;
  top: 66.666%;
}

.tOver:nth-child(1):hover ~ .tiltBox {
  transform: rotateX(2deg) rotateY(-2deg);
}
.tOver:nth-child(2):hover ~ .tiltBox {
  transform: rotateX(2deg) rotateY(0deg);
}
.tOver:nth-child(3):hover ~ .tiltBox {
  transform: rotateX(2deg) rotateY(2deg);
}
.tOver:nth-child(4):hover ~ .tiltBox {
  transform: rotateX(0deg) rotateY(-2deg);
}
.tOver:nth-child(5):hover ~ .tiltBox {
  transform: rotateX(0deg) rotateY(0deg);
}
.tOver:nth-child(6):hover ~ .tiltBox {
  transform: rotateX(0deg) rotateY(2deg);
}
.tOver:nth-child(7):hover ~ .tiltBox {
  transform: rotateX(-2deg) rotateY(-2deg);
}
.tOver:nth-child(8):hover ~ .tiltBox {
  transform: rotateX(-2deg) rotateY(0deg);
}
.tOver:nth-child(9):hover ~ .tiltBox {
  transform: rotateX(-2deg) rotateY(2deg);
}

.tOverReverse:nth-child(1):hover ~ .tiltBox {
  transform: rotateX(-2deg) rotateY(2deg);
}
.tOverReverse:nth-child(2):hover ~ .tiltBox {
  transform: rotateX(-2deg) rotateY(0deg);
}
.tOverReverse:nth-child(3):hover ~ .tiltBox {
  transform: rotateX(-2deg) rotateY(-2deg);
}
.tOverReverse:nth-child(4):hover ~ .tiltBox {
  transform: rotateX(0deg) rotateY(2deg);
}
.tOverReverse:nth-child(5):hover ~ .tiltBox {
  transform: rotateX(0deg) rotateY(0deg);
}
.tOverReverse:nth-child(6):hover ~ .tiltBox {
  transform: rotateX(0deg) rotateY(-2deg);
}
.tOverReverse:nth-child(7):hover ~ .tiltBox {
  transform: rotateX(2deg) rotateY(2deg);
}
.tOverReverse:nth-child(8):hover ~ .tiltBox {
  transform: rotateX(2deg) rotateY(0deg);
}
.tOverReverse:nth-child(9):hover ~ .tiltBox {
  transform: rotateX(2deg) rotateY(-2deg);
}
