.shine {
  mask-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  -webkit-mask-image: linear-gradient(
    -45deg,
    rgba(0, 0, 0, 0.6) 30%,
    #000 50%,
    rgba(0, 0, 0, 0.6) 70%
  );
  mask-size: 5%;
  -webkit-mask-size: 5%;
  animation: shine;
  animation-duration: 45s;
  animation-direction: normal;
  animation-fill-mode: forwards;
  animation-delay: 1s;
  animation-iteration-count: infinite;
}

@keyframes shine {
  from {
    -webkit-mask-position: -100%;
  }
  to {
    -webkit-mask-position: 200%;
  }
}
