.card {
  transform: perspective(800px) rotateY(25deg) scale(0.9);
  filter: blur(0.5px);
  opacity: 0.5;
  transition: 0.6s ease all;
  transition-delay: 0.2s;
}

.card:hover {
  transform: perspective(800px) translateY(-50px) rotateX(10deg) scale(1);
  filter: blur(0);
  opacity: 1;
  transition-delay: 0.1s;
}

.typewriter {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.1em; /* Adjust as needed */
  animation: typing 2.5s steps(30, end), blink-caret 0.5s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
