:root {
  --background: url("../img/asfalt-dark.webp");
  --easing: cubic-bezier(0.25, 1.7, 0.35, 0.8);
  --duration: 0.5s;
}

.header {
  position: fixed;
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
}

.open {
  backdrop-filter: blur(64px);
  animation: blur-in 0.5s ease;
}

.arrowContainer {
  border: 2px solid black;
}

.arrow {
  width: 1.25rem;
  height: 1.25rem;
  display: inline-block;
  position: relative;
  cursor: pointer;
}

.arrow span {
  top: 0.6rem;
  position: absolute;
  width: 0.75rem;
  height: 0.2rem;
  background-color: black;
  display: inline-block;
  transition: all 0.2s ease;
}

.arrow span:first-of-type {
  left: 1px;
  transform: rotate(45deg);
}

.arrow span:last-of-type {
  right: 0.5px;
  transform: rotate(-45deg);
}

.open .arrow span:first-of-type {
  top: 0.5rem;
  transform: rotate(-45deg);
}

.open .arrow span:last-of-type {
  top: 0.5rem;
  transform: rotate(45deg);
}

@keyframes blur-in {
  from {
    backdrop-filter: blur(16px);
    -webkit-backdrop-filter: blur(16px);
  }
  to {
    backdrop-filter: blur(64px);
    -webkit-backdrop-filter: blur(64px);
  }
}

@supports not (backdrop-filter: blur(10px)) {
  .header {
    color: white;
    background: rgba(72, 148, 86, 0.85);
  }

  .open {
    background: rgba(72, 148, 86, 1);
  }

  .arrowContainer {
    border: 2px solid white;
  }

  .arrow span {
    background-color: white;
  }
}
